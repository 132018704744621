.player-count-status {
  font-weight: bold;
  display: grid;
  grid-template-columns: repeat(5, max-content);
  height: fit-content;
  gap: 0.2rem;
  &.slots-6, &.slots-9 {
    grid-template-columns: repeat(3, max-content);
  }
  &.slots-7, &.slots-8, &.slots-11, &.slots-12 {
    grid-template-columns: repeat(4, max-content);
  }
  &.under {
    color: $warning;
  }
  &:not(.under) span.game-player-status {
    &.waitlist {
      color:$warning;
    }
    &.committed {
      color: $success;
    }
  }
}