.calendar {
  .month {
      width: 100%;
      display: inline-grid;
      grid-template-columns: 1fr max-content 1fr;
      font-size: x-large;
      font-weight: 800;
      padding: 0.25rem 0;
      @include tablet {
        font-size: xx-large;
        font-weight: 700;
      }
      .month-name {
          padding: 0 0.5rem;
      }
      .icon {
          display: inline-flex;
          flex-direction: row;
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          &:first-child {
              justify-content: flex-end;
          }
      }
  }
  .view {
      width: 100%;
      padding: 0 1em;
      @include tablet {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        padding: 0 4rem 0;
        max-width: 2200px;
        margin: 0 auto;
      }
      .month-week-day {
          display: none; // only shows on desktop
          @include tablet-only {
            font-size: large;
          }
          @include desktop {
            font-size: x-large;
          }
          @include tablet {
            display: block;
            text-align: center;
            font-weight: 600;
          }
      }
  }
}