.prizes {
  @include desktop {
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 2rem;
  }
  @include touch {
    overflow-x: hidden;
    margin: 0 1rem;
  }
  .add-prize {
    width: 100%;
    margin: 1em 0;
  }
  .prize-gallery {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    justify-content: center;
  }
  .prize-search {
    margin-bottom: 1rem;
    margin-top: 1rem;
    .control.has-icons-left, .control.has-icons-right {
      .icon {
        pointer-events: all;
        cursor: pointer;
      }
    }
  }
  .button.load-more {
    margin: auto;
  }
}