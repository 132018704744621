.event {
    &.event-date {
        height: fit-content;
        margin-left: 1em;
        font-weight: 600;
        font-size: small;
        cursor: pointer;
        &.cancelled {
            text-decoration: line-through;
        }
        @include tablet-only {
            font-size: smaller;
        }
        @include widescreen {
            font-size: medium;
        }
        @include fullhd {
            font-size: large;
        }
    }
    .max-players-warning {
        margin-top: 0.5rem;
        text-align: center;
    }
    &.cancelled-event {
        text-transform: uppercase;
        font-weight: 800;
        color: $danger;
        margin-left: 1em;
    }
}
.event.event-detail {
    padding: 1rem 0;
    margin: 0 1rem 0 1rem;
    border-bottom: 1px solid $dark;
    .title {
        margin-bottom: 0;
        &.cancelled {
            text-decoration: line-through;
        }
    }
    .subtitle {
        margin-top: 0;
    }
    .is-cancelled {
        font-weight: 800;
        text-transform: uppercase;
        font-size: large;
    }
    .top-row {
        display: flex;
        flex-direction: row;
        .event-names {
            flex-grow: 1;
            flex-basis: fit-content;
            .subtitle {
                font-weight: 600;
            }
        }
        .dropdown {
            flex-shrink: 1;
            flex-basis: 0;
            align-items: flex-end;
            justify-content: right;
            width: 2rem;
        }
        .location-address {
            font-size: large;
            font-weight: 600;
        }
    }
    .icon {
        color: $outer-space;
    }
}