.event-attendees {
  .card-content {
    max-height: 90vh;
    overflow-y: auto;
  }
}
.attendees-list {
  display: grid;
  grid-template-columns: max-content 1fr;
  row-gap: 1rem;
  margin-top: 1rem;
  &.status {
    grid-template-columns: max-content 1fr min-content;
  }
  &.token {
    grid-template-columns: 1fr max-content;
    .field {
      margin-bottom: 0;
    }
  }
  & > div {
    padding-bottom: 1rem;
    &:not(:last-child, :nth-last-child(2)) {
      border-bottom: 1px solid $outer-space;
    }
  }
  & > div.badges {
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    span.icon {
      height: 2rem;
      width: 2rem;
      font-size: 0.85rem;
      background-color: $info;
      color: $light;
      margin: 0 0.3rem;
    }
  }
  .check-toggle {
    i {
      color: $warning;
      &.active {
        color: $success;
      }
    }
  }
}