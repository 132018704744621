.game-view {
  display: contents;
  & > :nth-child(5) {
    text-align: center;
  }
  .flex-badge {
    order: 4;
  }
  .private-badge {
    order: 3;
  }

  @include mobile {
    & > :nth-child(1) { // system information
      grid-column: 1/-1;
    }
    & > :nth-child(2) { // player count status
      justify-self: end;
      grid-column: 2/-1;
    }
    & > :nth-child(3) { // edit menu
      grid-column: 3;
    }
    & > :nth-child(4) { // host player icon
      grid-column: 1;
      justify-self: left;
      padding-left: 2rem;
      &.flat-game {
        margin-left: 2rem;
      }
    }
    & > :nth-child(5) { // title
      grid-column: 1/3;
      text-align: left;
      padding-left: 2rem;
    }
    .systems-container {
      flex-direction: column;
      width: 100%;
    }
    &.line-1 { & > :nth-child(3), & > :nth-child(5) { grid-row: 2; } & > :nth-child(4) { grid-row: 3; } }
    &.line-2 { & > :nth-child(3), & > :nth-child(5) { grid-row: 6; } & > :nth-child(4) { grid-row: 7; } }
    &.line-3 { & > :nth-child(3), & > :nth-child(5) { grid-row: 10; } & > :nth-child(4) { grid-row: 11; } }
    &.line-4 { & > :nth-child(3), & > :nth-child(5) { grid-row: 14; } & > :nth-child(4) { grid-row: 15; } }
    &.line-5 { & > :nth-child(3), & > :nth-child(5) { grid-row: 18; } & > :nth-child(4) { grid-row: 19; } }
    &.line-6 { & > :nth-child(3), & > :nth-child(5) { grid-row: 22; } & > :nth-child(4) { grid-row: 23; } }
    &.line-7 { & > :nth-child(3), & > :nth-child(5) { grid-row: 26; } & > :nth-child(4) { grid-row: 27; } }
    &.line-8 { & > :nth-child(3), & > :nth-child(5) { grid-row: 30; } & > :nth-child(4) { grid-row: 31; } }
    &.line-9 { & > :nth-child(3), & > :nth-child(5) { grid-row: 34; } & > :nth-child(4) { grid-row: 35; } }
    &.line-10 { & > :nth-child(3), & > :nth-child(5) { grid-row: 38; } & > :nth-child(4) { grid-row: 39; } }
  }
  
  @include tablet {
    &.line-1 { & > :nth-child(4) { grid-row: 1; } & > :nth-child(5) { grid-row: 1; } }
    &.line-2 { & > :nth-child(4) { grid-row: 3; } & > :nth-child(5) { grid-row: 3; } }
    &.line-3 { & > :nth-child(4) { grid-row: 5; } & > :nth-child(5) { grid-row: 5; } }
    &.line-4 { & > :nth-child(4) { grid-row: 7; } & > :nth-child(5) { grid-row: 7; } }
    &.line-5 { & > :nth-child(4) { grid-row: 9; } & > :nth-child(5) { grid-row: 9; } }
    &.line-6 { & > :nth-child(4) { grid-row: 11; } & > :nth-child(5) { grid-row: 11; } }
    &.line-7 { & > :nth-child(4) { grid-row: 13; } & > :nth-child(5) { grid-row: 13; } }
    &.line-8 { & > :nth-child(4) { grid-row: 15; } & > :nth-child(5) { grid-row: 15; } }
    &.line-9 { & > :nth-child(4) { grid-row: 17; } & > :nth-child(5) { grid-row: 17; } }
    &.line-10 { & > :nth-child(4) { grid-row: 19; } & > :nth-child(5) { grid-row: 19; } }
    & > :nth-child(2) { grid-column: 4; }
    & > :nth-child(3) { grid-column: 5; }
    & > :nth-child(4) { grid-column: 2; }
    & > :nth-child(5) { grid-column: 3; }
    .systems-container {
      
    }
  }
  .flat-game {
    background-color: $light;
    padding: 0.5rem;
    border-radius: 5rem;
    font-size: large;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.separator {
  grid-column: 1/-1;
  border-top: 1px solid $dark;
  text-align: center;
  
  legend {
    padding: 0 1rem;
    font-size: large;
    font-weight: 600;
  }

  &.flex-start {
    margin: 1rem 0;
  }

  &:last-of-type {
    display: none;
  }
}