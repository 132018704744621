.game-detail-view {
  padding: 0 1rem;
  @include mobile {
    padding: 0 0.5rem;
  }
  height: 100%;
  display: flex;
  flex-direction: column;

  .flat-game {
    background-color: $light;
    padding: 0.5rem;
    border-radius: 5rem;
    font-size: large;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .game-header {
    display: flex;
    flex-direction: row;

    .game-badge.header {
      @include desktop {
        display: none;
      }
      @include mobile {
        display: none;
      }
    }

    .icon.menu-nav {
      @include desktop {
        display: none;
      }
      align-self: center;
    }

    .dropdown.is-right {
      flex-shrink: 2;
      width: fit-content;
      margin: auto 0;
      .icon.is-large {
        width: 2rem;
      }
    }
  }

  .badges-detail {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: flex-start;
    @include tablet-only {
      display: none;
    }
    .game-badge {
      width: fit-content;
    }
  }

  .system-icon {
    flex-direction: row-reverse;
    flex-grow: 1;
    justify-content: space-between;
    @include mobile {
      flex-wrap: wrap-reverse;
      justify-content: center;
    }
    
    .system-name {
      font-size: xx-large;
      flex-grow: 1;
      width: fit-content;
      @include tablet {
        font-size: x-large;
      }
      @include mobile {
        font-size: large;
      }
      padding: 0.5rem 2rem;
    }

    .host-info {
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      gap: 0.5rem;
      margin-right: 2rem;
    }

    .game-status {
      order: 1;
    }
    figure.game-status {
      margin-left: 0.5rem;
    }
  }

  .game-description {
    font-style: italic;
    padding: 0 2rem;
  }

  .game-title {
    margin-top: 1rem;
    text-decoration: underline;
  }

  .game-title {
    padding: 0 1rem;
  }

  .game-details {
    padding: 0 1.5rem;
    margin: 1rem 0;
    list-style: disc;
    font-weight: bold;
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 2.5rem;
    @include desktop {
      margin: auto auto 1rem;
      min-width: 400px;
    }
    margin-bottom: 1rem;
  }

  .players-by-system {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .player-system-list {
      flex-grow: 1;
    }
  }
}
.react-swipeable-view-container {
  & > div:last-child {
    .menu-nav:last-child {
      display: none;
    }
  }
}