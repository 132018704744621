.token-coin {
  border-radius: 50em;
  padding: 0.5em;
  font-size: larger;
  font-weight: bold;
  height: 2rem;
  line-height: 0.75rem;
  background-color: $warning;
  color: $light;
  &.negative {
    background-color:$danger;
  }
}