.prize-award {
  .confirm-message, .instructions {
    width: 100%;
    text-align: center;
  }
  .bid-select-list {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
    .token-item {
      display: flex;
      flex-direction: row;
      margin: 1rem;
      padding: 1rem;
      &:not(:last-child) {
        border-bottom: 1px solid $dark;
      }
      &:not(:first-child) {
        margin-top: 0;
      }
      &.selected {
        border: 2px solid $success;
      }
      .player-avatar {
        margin-right: 1rem;
      }
      .token-coin {
        margin-left: 1rem;
        padding: 0.25rem 0.75rem;
        background-color: $warning;
      }
    }
  }
}