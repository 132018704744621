.workshop {
    min-height: 100vh;
    .tabs {
        @include desktop {
            display: none;
        }
    }
    .react-swipeable-view-container {
        @include desktop {
            display: grid !important;
            transform: none !important;
            grid-template-columns: repeat( 2, 1fr );
            margin-top: 1rem;
            [data-swipeable] {
              &:nth-child(even) {
                border-left: 1px solid $dark;
                padding-bottom: 8rem;
              }
            }
        }
        h3.subtitle {
            display: none;
            text-align: center;
            @include desktop {
                display: block;
                margin: 0 auto;
            }
        }
        button {
            width: 100%;
            margin: 0.5rem;
        }
        div[data-swipeable] {
          overflow-x: hidden !important;
          overflow-y: visible !important;
          word-break: break-word;
        }
    }

    .begin-event {
        margin-left: 1rem;
        width: 100%;
        margin-top: 1rem;
    }

    .topic-container, .prize-container {
        margin-right: 1rem;
    }

    .topics-list {
        margin: 0 auto;
    }

    .prize-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        width: max-content;
        max-width: 100%;
        margin: 0 auto;
        padding: 1rem;
        justify-content: center;
    }
}