.markdown {
  padding: 1rem;

  h1, h2, h3, h4 {
    margin: 1.414rem 0 0.5rem;
    font-weight: inherit;
    line-height: 1.42;
  }
  h1, h2 {
    border-bottom: 1px solid $timberwolf;
  }

  h1 {
    margin-top: 0;
    font-size: 3.998rem;
  }
  h2 {
    font-size: 2.827rem;
  }
  h3 {
    font-size: 1.999rem;
  }
  h4 {
    font-size: 1.414rem;
  }
  h5 {
    font-size: 1.121rem;
  }
  h6 {
    font-size: 0.88rem;
  }
  small {
    font-size: 0.707rem;
  }
  img,
  canvas,
  iframe,
  video,
  svg,
  select,
  textarea {
    max-width: 100%;
  }

  hr {
    height: 4px;
    background-color: $timberwolf;
  }

  pre, code {
    background-color: $timberwolf;
  }

  blockquote {
    border-left: 4px solid $info;
    padding: 0 0.75rem;
    color: $info;
    margin-bottom: 1rem;
  }

  p, ul, ol {
    margin-bottom: 1rem;
  }

  ul, ol {
    padding-left: 1.5rem;
  }

  ul { 
    list-style-type: disc; 
  }
  ol { 
      list-style-type: decimal; 
  }
  ul ul, ol ul { 
      list-style-type: circle; 
      margin-left: 1.5rem; 
  }
  ol ol, ul ol { 
      list-style-type: lower-latin; 
      margin-left: 1.5rem; 
  }
}