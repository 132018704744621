.player-history-game {
  display: contents;
  .event-info {
    font-weight: 600;
    font-size: larger;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $info;
    width: fit-content;
    grid-column: 1/4;
    .event-location {
      padding-left: 1rem;
    }
    &:before {
      display: inline-block;
      content: '\2022';
      margin-right: 0.5rem;
    }
  }
  .game-info {
    display: contents;
    .game-title {
      padding: 0 1rem 1rem 1rem;
      text-align: center;
      align-self: center;
      font-size: large;
      font-weight: 600;
    }
    .system-icon {
      margin-bottom: 1.5rem;
      margin-left: 1rem;
    }
    .tag {
      background-color: $info;
      color: $light;
      font-size: large;
      align-self: baseline;
      padding: 0 1.25rem;
      margin: 0 0.5rem;
    }
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid $dark;
  }
}