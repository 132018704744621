.event-code {
  @include mobile {
    .modal-content {
      max-width: 100%;
      width: 100%;
      height: 100vh;
    }
    .card {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      .card-content {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
  img.image.is-square {
    width: 100%;
    padding-top: 0;
  }
}