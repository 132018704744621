.topic-header {
  display: flex;
  flex-direction: row;
  &.no-description {
    margin-left: 1rem;
    &:not(:last-child) {
      border-bottom: 1px solid $dark;
    }
  }
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
  h3 {
    font-size: large;
    font-weight: 600;
    margin-left: 1rem;
  }
  .icon {
    margin-left: auto;
    span {
      margin-left: 0.5rem;
    }
  }
  .dropdown {
    flex-basis: 0;
    flex-shrink: 1;
  }
}
.topic {
  margin-left: 0.5rem;
  .collapse-body {
    margin-left: 3rem;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $dark;
  }
  .dropdown {
    flex-basis: 0;
    flex-shrink: 1;
  }
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
}
.upvote {
  margin-top: 0.75rem;
}