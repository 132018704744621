.locations {
    margin: 1rem 1rem 0;
    .locations-table {
        width: 100%;
        tr {
            td:last-child {
                text-align: right;
            }
            /* hide address on mobile */
            @include mobile {
                td:nth-child(2), th:nth-child(2) {
                    display: none;
                }
            }
        }
    }
    h3 {
        text-align: center;
    }
    button {
        width: 100%;
    }
}
