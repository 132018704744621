.player-icon {
  display: flex;
  flex-direction: row;
  align-items:flex-start;
  flex-wrap: wrap;

  &.large {
    .player-avatar {
      height: 32px;
      width: 32px;
      i {
        font-size: 28px;
      }
    }
    .player-name {
      font-size: large;
    }
  }

  &.waitlisted {
    box-shadow: 1px 0px 0px 0.25rem $warning inset;
  }

  .player-avatar {
    width: 1.35rem;
    height: 1.35rem;
    z-index: 1;
    &.icon {
      border-radius: 200em;
      i:before {
        position: relative;
        z-index: -1;
      }
    }
    &.icon, img, div.crop-image {
      border-width: 0.25rem;
      border-style: none;
      min-width: 32px;
      min-height: 32px;
    }
    &.cancelled {
      &.icon, img, div.crop-image {
        border-style: solid;
        border-color: $danger;
      }
    }
    &.reserved, &.responded {
      &.icon, img, div.crop-image {
        border-style: solid;
        border-color: $warning;
      }
    }
    &.confirmed {
      &.icon, img, div.crop-image {
        border-style: solid;
        border-color: $success;
      }
    }
  }

  .player-name {
    display: flex;
    flex-direction: column;
    padding-left: 0.25rem;
    strong {
      white-space: break-spaces;
      max-width: 50vw;
    }
    @include tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}