.system-preference {
  .control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    .field {
      .checkbox.b-checkbox {
        align-items: center;
        input[type=checkbox] {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }
  }
}