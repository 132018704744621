.systems {
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 2rem;
    form .field {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        .control {
            flex-grow: 1;
            .icon {
                pointer-events: initial;
            }
        }
    }
    .systems-table {
        width: 100%;
        tr {
            td:last-child {
                text-align: right;
            }
            /* hide address on mobile */
            @include mobile {
                td:nth-child(2), th:nth-child(2) {
                    display: none;
                }
            }
        }
    }
    h3 {
        text-align: center;
    }
    button {
        width: 100%;
        margin-bottom: 2rem;
    }
}
