.event-menu {
  margin: 1em;
  @include mobile {
    margin: 1rem 0.5rem;
  }
  min-height: 80vh;
  /* this should really move to fixes */  
  .hidden {
      display: none;
  }

  .react-swipeable-view-container {
      min-height: 80vh;
      
      @include desktop {
          display: grid !important;
          transform: none !important;
          grid-template-columns: repeat( 2, 1fr );
          [data-swipeable] {
            margin-bottom: 1rem;
            overflow: hidden !important;
            &:first-child {
                display: none;
            }
            &:nth-child(odd) {
              border-left: 1px solid $dark;
            }
            &:not(:last-child):not(:nth-last-child(2):nth-child(even)) {
              border-bottom: 1px solid $dark;
            }
          }
      }
      div[data-swipeable] {
        overflow: hidden;
        word-break: break-word;
      }
  }

  .games-list {
    display: grid;
    align-items: center;
    gap: 1rem 1rem;
    grid-template-columns: max-content max-content 1fr max-content max-content;
    @include mobile {
      grid-template-columns: max-content 1fr max-content;
    }
  }
  .add-game {
    grid-column: 1/-1;
    width: calc(100% - 2rem);
    margin: 1rem;
    &.desktop {
      display: none;
      @include desktop {
        display: block;
      }
    }
  }
}