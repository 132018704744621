.player-list {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding: 0 1rem;

  & > div {
    margin: 2rem 0;
    & > h5 {
      border-bottom: 1px solid $dark;
    }
  }
  .waitlist {
    margin-top: 1rem;
  }

  .player-icon {
    margin-top: 1rem;
    padding-bottom: 1rem;
    &:not(:last-child) {
      border-bottom: 1px solid $dark;
    }
    .player-name {
      white-space: nowrap;
      flex-grow: 1;
    }
    .dropdown.is-right {
      justify-content: flex-end;
      width: fit-content;
    }
  }
}