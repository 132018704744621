html.has-navbar-fixed-top {
    min-height: 100%;
}
body, div#root, div.App, div.App > div:last-child, div.rpg-body, .expand-height{
    min-height: calc(100vh - 4rem);
}
.App {
    nav.navbar {
        border-radius: 0;
        .title {
            color: $light;
            font-weight: 700;
        }
        a:hover, a:visited, a:active {
            color: $light;
        }
        .navbar-end {
            .token-coin {
                margin: 0.75rem;
            }
        }
        .authenticator {
            margin: 0.6rem 0.6rem 0 0;
            cursor: pointer;
            figure.image {
                height: 40px;
                width: 40px;
                img {
                    height: 40px;
                    width: 40px;
                    max-height: 40px;
                }
                i {
                    font-size: 2.5rem;
                }
            }
        }
        .navbar-item {
            cursor: pointer;
            a, a:visited {
                color: inherit;
            }
            text-transform: capitalize;
        }
        .navbar-burger {
            color: $light;
        }
        .navbar-dropdown > a.navbar-item > span {
            color: $dark;
        }
        /* should be mobile only */
        .navbar-menu.is-active {
            border-radius: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: 1rem 2rem;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
              
            &:hover {
                box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
            }

            .authenticator {
                figure {
                    display: none;
                }
            }
        }
    }
    .modal.login {
        .card-content {
            display: grid;
            grid-template-columns: max-content;
            justify-content: center;
            margin: 0 auto;
            gap: 1rem;
            
            button.button {
                width: 100%;
            }
            & > span {
                display: block;
                width: 100%;
                text-align: center;
                &:before, &:after {
                    content: "-";
                    margin: 0 1rem;
                    display: inline-block;
                    transform: scale(5, 1);
                }
            }
            #discord-login {
                background-color: #5865f2;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 32px;
                min-width: 200px;
                max-width:400px;
                font-size: 14px;
                font-weight: 500;
                font-family: Roboto,sans-serif;
                line-height: 16px;
                padding: 2px 16px;
                border: 0;
                border-radius: 3px;
                box-sizing: border-box;
                cursor: pointer;
                transition: background-color .15s ease,color .15s ease;
            }
            #google-login {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
        }
    }
    .select {
        width: 100%;
        select {
            width: 100%;
        }
    }
    .dropdown {
        width: 100%;
        .dropdown-content {
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        }
        .dropdown-trigger.control {
            width: 100%;
            &.has-icons-right.has-selection {
                span.icon.is-right {
                    height: 3em;
                }
            }
        }
    }
    .collapsible {
        & > .collapse-body {
            transition: max-height 1s cubic-bezier(0,1,0,1);
        }
        &.active {
            & > .collapse-body {
                transition: max-height 2s ease-in;
                overflow: visible;
            }
        }
    }

    /*-- this should be on mobile only? --*/
    .modal-content {
        max-height: 100vh;
        .card {
            display: flex;
            flex-direction: column;
            max-height: 100%;
            .card-content {
                flex: 1;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }
    textarea[rows] {
        height: auto;
        resize: none;
    }

    .click-interceptor {
        position: fixed;
        z-index: 1000;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @include tablet {
            transform: none !important;
            &.mobile {
                display: none;
            }
        }
        @include mobile {
            &.desktop {
                display: none;
            }
        }
    }
    .dropdown-menu {
        z-index: 1001;
    }
    .control-row {
        display: flex;
        flex-direction: row;
        column-gap: 1em;
        justify-content: center;
        .field {
            min-width: 5em;
            flex-grow: 0;
            flex-shrink: 1;
            &:last-child {
                margin-bottom: 0.75rem;
            }
        }
    }
    .checkbox {
        flex-direction: column;
        margin: 0;
        width: 100%;
        height: 100%;
        .label {
            padding: 0;
            text-align: center;
        }
        input {
            height: 100%;
            min-height: 2.5rem;
            min-width: 2.5rem;
            margin: 0 auto;
        }
    }
    .user-suggestion {
        display: flex;
        flex-direction: row;
        width: fit-content;
        gap: 0.5rem;
        align-items: flex-end;
        background-color: $light;
        padding: 0.3rem;
        border-radius: 2rem;
        font-size: 1.2rem;
        .icon.is-small {
            position: relative;
            height: 2.2rem;
            i {
                font-size: 2rem;
                color: $dark;
                margin-left: 0.5rem;
            }
        }
        image {
            display: inline-block;
        }
        :last-child {
            margin-right: 1rem;
        }
    }
    .clickable {
        cursor: pointer;
    }
}
