.player-history {
  .card-content {
    max-height: 90vh;
    overflow-y: auto;
  }
  .user-info {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $dark;

    & > .icon.is-large {
      padding: 2rem;
    }
    & > div {
      display: flex;
      flex-direction: column;
      padding-left: 1rem;
    }
    .user-name {
      font-size: x-large;
      font-weight: 600;
    }
    .member-since {
      font-size: medium;
      font-weight: 600;
    }
    & > span {
      font-size: medium;
      font-weight: 600;
    }
  }
  .player-games-list, .player-tokens-list {
    display: grid;
    padding: 0 1rem;
    grid-template-columns: max-content 1fr max-content;
  }
  .player-tokens-summary {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    padding-top: 1.5rem;
    border-top: 1px solid $outer-space;
    .player-tokens-balance {
      flex-grow: 1;
      text-align: right;
      padding-right: 2rem;
      font-weight: bolder;
      font-size: large;
    }
  }
  .token-item {
    display: contents;
    &:not(:last-child) {
      & > div, & > span {
        border-bottom: 1px solid $dark;
      }
    }
    .date, .description, .bid-amount {
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;
    }
    .description {
      text-align: right;
      padding-right: 1rem;
    }
    .bid-amount {
      text-align: right;
      .token-coin {
        padding: 0.25rem 0.5rem;
        &.positive {
          background-color: $success;
        }
      }
    }
  }
  .tab-window {
    section:not(.is-active) {
      display: none;
    }
  }
}