.game-status {
  font-size: xx-large;
  &.has-text-warning, .has-text-success, .has-text-danger {
    & > img, & > div {
      border-width: 2px;
      border-style: solid;
    }
    &.has-text-warning {
      & > img, & > div {
        border-color: $warning;
      }
    }
    &.has-text-success {
      & > img, & > div {
        border-color: $success;
      }
    }
    &.has-text-danger {
      & > img, & > div {
        border-color: $danger;
      }
    }
  }
}