.date-view {
    @include tablet-only {
        font-size: small;
        padding-top: 2rem;
    }
    @include desktop-only {
        font-size:medium;
        padding-top: 3rem;
    }
    @include widescreen {
        font-size: large;
        padding-top: 5rem;
    }
    @include tablet {
        font-weight: bold;
        position:relative;
        aspect-ratio: 4/3;
        overflow-y: hidden;
        border: 1px solid $info;
        &.active {
            color: $primary;
            .date-identifier {
                background-color: $wash;
            }
        }
        &.inactive {
            color: $info;
        }
    }
    &.selected {
        box-shadow: inset 0px 0px 2px 4px #748B92;
        span {
            text-decoration: underline;
        }
    }
    @include mobile {
        border-top: 1px solid $dark;
        &:last-child {
            border-bottom: 1px solid $dark;
        }
        padding: 1em 0;
        display: flex;
        flex-direction: row;
        position: relative;
        min-height: 1.5rem;
        &.active {
            .date-identifier {
                background-color: $dark;
                border: 2px solid $wash;
            }
        }
        &.inactive {
            display: none;
        }
    }
    .date-identifier {
        @include mobile {
            width: 3rem;
            height: 3rem;
            margin: 1rem;
            span {
                line-height: 0.9rem;
                &.date-number {
                    font-size: 1.5rem;
                }
            }
        }
        @include tablet-only {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0.5rem;
            span {
                line-height: 0.5rem;
                &.date-number {
                    font-size: 0.75rem;
                }
            }
        }
        @include desktop-only {
            width: 2rem;
            height: 2rem;
            margin: 0.35rem;
            span {
                line-height: 0.7rem;
                &.date-number {
                    font-size: 1.1rem;
                }
            }
        }
        @include widescreen {
            width: 3rem;
            height: 3rem;
            margin: 1rem;
            span {
                line-height: 0.9rem;
                &.date-number {
                    font-size: 1.5rem;
                }
            }
        }
        @include tablet {
            position: absolute;
            top: 0;
            margin-right: 100%;
        }
        color: $light;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.35rem 0.75rem 0.5rem;
        border-radius: 6rem;
        background-color: $info;
        span {
            padding: 0;
            &.week-day {
                font-size: smaller;
            }
            &.date-number {
                font-weight:800;
                flex-grow: 1;
            }
        }
    }
    .date-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include tablet {
            position: relative;
            z-index: 5;
        }
    }
}