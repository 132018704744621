.prize-detail {
  header.card-header {
    .prize-menu {
      width: fit-content;
    }
  }
  .bid-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;

    .field {
      display: flex;
      flex-direction: row;
      label {
        align-self: center;
        margin-right: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
  .bid-header {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $dark;
    position: relative;
    .icon {
      position: absolute;
      right: 2rem;
    }
  }
  .bid-list {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 1rem;
    width: fit-content;
    margin: 0 auto;
    .token-item {
      display: contents;
      .player-icon {
        justify-self: flex-end;
        background-color: $light;
        border-radius: 5rem;
        padding: 0.5rem;
        .player-avatar {
          margin-bottom: 0.65rem;
        }
        .player-name {
          margin: 0 0.5rem 0 1rem;
          align-self: center;
        }
      }
      .bid-amount {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        gap: 0.25rem;
      }
    }
  }
  .won-info {
    .player-icon {
      display: inline-flex;
      .image.player-avatar {
        margin-left: 0.25rem;
        margin-right: 0.75rem;
      }
    }
  }
}