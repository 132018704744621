.location-address {
  flex-shrink: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  column-gap: 0.1rem;
  justify-content: flex-end;
  height: 1rem;
  &.stack {
    flex-direction: column;
    flex-flow: column;
  }
  @include tablet {
    span {
      white-space: nowrap;
    }
  }
}
.edit-location {
  div.game-details {
      display: grid;
      grid-template-columns: 1fr 4rem 8rem;
      gap: 1rem;
      h6{
          text-align: center;
          margin-bottom: 0;
          font-weight: bold;
          color: $dark;
      }
      :nth-child(1), :nth-child(2), :nth-child(3), :nth-child(4) {
          grid-column: 1/4;
      }
      margin-bottom: 0.25rem;
  }
}