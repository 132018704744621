.system-icon {
  background-color: $light;
  padding: 0.5rem;
  border-radius: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  &.short {
    .systems-container {
      flex-direction: column;
    }
  }
  .game-status {
    flex-shrink: 0;
  }
  .system-name {
    color: $dark;
    font-weight: 600;
    font-size: large;
    display: inline-block;
    width: 100%;
    text-align: center;
    .short-name {
      display: inline-block;
      @include desktop {
        display: none;
      }
    }
    .long-name {
      display: none;
      @include desktop {
        display: inline-block;
      }
    }
  }
  .system-or {
    font-weight: 600;
    text-decoration: underline;
  }
  .systems-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .systems-more {
    white-space: nowrap;
    margin-right: 0.5rem;
  }
}