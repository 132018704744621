@charset "utf-8";
// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

@import "./fonts.scss";

/* Bulma */
@import "./definition.scss";
@import "./bulma.scss";

/* Font Awesome */
@import "./font-awesome.scss";

/* boltcave dependencies */
@import "../../../node_modules/@vaettyr/boltcave-client-core/dist/esm/index.css";
@import "../../../node_modules/@vaettyr/boltcave-media-client/dist/esm/index.css";

@import "./fixes.scss";
@import "./markdown.scss"; // global for markdown display

/*import our styles*/
@import "../components/index.scss";
/*changing the way we do this*/
@import "../pages/calendar/calendar.style.scss";
@import "../pages/menu/menu.style.scss";
@import "../pages/location/location.style.scss";
@import "../pages/system/system.style.scss";
@import "../pages/template/template.page.scss";
@import "../pages/about/about.style.scss";
@import "../pages/prizes/prize.style.scss";
@import "../components/date/date.style.scss";
@import "../components/event/event.style.scss";
@import "../components/location/location.style.scss";
