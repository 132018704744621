// Setting Bulma Custom Variables

// Custom Colors I like, not sure what I'm going to do with them yet
$timberwolf: #D6DBD2;
$dim-gray: #6F686D;
$payne-grey: #597081;
$bone: #D8D0C1;
$gunmetal: #2C363F;
$cadet-grey: #A2ABAB;
$sage: #BBC7A4;
$ivory: #F2F5EA;

$shadow: #00121980;
$shade: #03071e;
$wash: #212529;

$chocolate-cosmos: #6C0E23;
$indian-red: #C1666B;

$oxford-blue: #0E1428;
$space-cadet: #21295C;
$yale-blue: #1B3B6F;
$lapis-lazuli: #065A82;
$cerulean: #1C7293;
$outer-space: #36494E;
$powder-blue: #9EB3C2;

$hookers-green: #5B7B7A;

$rose-taupe: #8C6057;
$beige: #EFEFD0;
$ecru: #D4B483;
$peach: #F7C59F;
$crayola-orange: #FF6B35;
$cinnabar: #D95D39;
$tangerine: #F18805;
$gamboge: #F0A202;
$coffee: #684E32;
$sepia: #5D3A00;
$walnut-brown: #5C5346;
$atomic-tangerine: #F98948;

// bulma variable redefinition
$light: $timberwolf;
$dark: $shade;

// default definition (light)
$primary: $outer-space;
$info: #748B92;
$success: #5f8b6b;
$warning: $gamboge;
$danger: $chocolate-cosmos;

// Update Bulma's global variables
$family-sans-serif: "Grenze", sans-serif;
// $primary: #17151B;
$body-background-color: #E4E6E3;

// Update some of Bulma's component variables
$control-border-width: 2px;
//$input-border-color: transparent;
//$input-shadow: none;