.templates {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 2rem;

  .templates-table {
      width: 100%;
      tr {
          td:last-child {
              text-align: right;
          }
          @include mobile {
            /* hide title and info on mobile */
            td:nth-child(3), th:nth-child(3),
            td:nth-child(4), th:nth-child(4),
            td:nth-child(5), th:nth-child(5),
            .long-system {
              display: none;
            }
          }
      }
  }
  h3 {
    text-align: center;
  }
  button {
      width: 100%;
      margin-bottom: 2rem;
  }
}
