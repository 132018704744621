@font-face {
    font-family: "Grenze";
    src: url(../fonts/grenze/Grenze-Regular.ttf) format('truetype');
}
@font-face {
    font-family: "Grenze";
    src: url(../fonts/grenze/Grenze-Thin.ttf) format('truetype');
    font-weight: 100;
}
@font-face {
    font-family: "Grenze";
    src: url(../fonts/grenze/Grenze-ExtraLight.ttf) format('truetype');
    font-weight: 200;
}
@font-face {
    font-family: "Grenze";
    src: url(../fonts/grenze/Grenze-Light.ttf) format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: "Grenze";
    src: url(../fonts/grenze/Grenze-Medium.ttf) format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: "Grenze";
    src: url(../fonts/grenze/Grenze-SemiBold.ttf) format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: "Grenze";
    src: url(../fonts/grenze/Grenze-Bold.ttf) format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: "Grenze";
    src: url(../fonts/grenze/Grenze-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}
@font-face {
    font-family: "Grenze";
    src: url(../fonts/grenze/Grenze-Black.ttf) format('truetype');
    font-weight: 900;
}