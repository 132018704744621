.system-picker {
  flex-basis: min-content;

  .dropdown {
    display: block;
  }

  .dropdown-trigger.control {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: hsl(0,0%,100%);
    /*border stuff*/
    border-width: 2px;
    border-radius: 4px;
    border-style: solid;
    border-color: hsl(0, 0%, 86%);
    outline: none;
    &.is-danger {
      border-color: $danger;
    }
    &:hover {
      border-color: hsl(0, 0%, 71%);
      box-shadow: none;
    }
    &:focus-within {
      border-color: hsl(229, 53%, 53%);
      box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25);
    }
    .tag:first-child {
      margin-left: 0.5rem;
    }
    input.input {
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: max-content;
      min-width: 200px;
      border: none;
      &:hover, &:active, &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
}