.game-badge {
  padding: 0.5rem;
  border-radius: 2rem;
  font-weight: bold;
  margin: 0 1rem;
  display: flex;
  flex-direction: row;
  padding-right: 0.5rem;
  white-space: break-spaces;
}

.badge-name {
  white-space: nowrap;
}

.flex-badge {
  background-color: $powder-blue;
  color: $timberwolf;
}

.private-badge {
  background-color: $warning;
  color: $light;
}