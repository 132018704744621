.about-page {
  .tabs {
    text-transform: capitalize;
  }
  .site-content {
    position: relative;
    section {
      &:not(.is-active) {
        display: none;
      }
      button.button {
        margin: 0 auto;
        display: block;
        margin-top: 1rem;
      }
    }
  }

  .markdown {
    margin: 0 auto;
    width: fit-content;
  }

  .icon-set {
    position: absolute;
    right: 0;
    z-index: 100;
  }

  .faq {
    border-bottom: 1px solid $dark;
    margin-bottom: 1rem;
    .content-header {
      font-size: x-large;
      font-weight: 600;
      padding-bottom: 0.5rem;
      .icon {
        font-size: medium;
      }
    }
     .collapse-body {
      margin-left: 2rem;
      p {
        padding: 1rem 0;
      }
     }
  }

  .content-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-size: x-large;
    font-weight: 600;
    margin-bottom: 1rem;
    color: $outer-space;
    text-decoration: underline;
    figure.media-image {
      margin-right: 1rem;
    }
  }

  @include desktop {
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 2rem;
  }

  .content-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin-top: 1rem;
    border: 1px solid $light;
    border-radius: 1rem;
    padding: 1rem 0;
  }
}
