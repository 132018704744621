.game-edit {
  .control-row {
    &.details {
      .field:first-child {
        flex-grow: 1;
      }
    }
    &.players {
      .field {
        flex-grow: 1;
      }
    }
    .field {
      &.autocomplete {
        flex-grow: 1;
        flex-shrink: 0;
        .dropdown-content {
          .user-suggestion {
            .icon.is-small {
              height: 1rem;
              margin-left: 0.85rem;
              margin-bottom: 0.35rem;
              i {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
  .widget-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}
.preview-description {
  @include desktop {
    .modal-content {
      width: 100%;
      max-width: 80vw;
      .card {
        max-height: 100vh;
        overflow: hidden;
        .card-content {
          max-height: 90vh;
          overflow: auto;
        }
      }
    }
  }
}