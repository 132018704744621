.prize-pod {
    border-radius: 2em;
    box-shadow: 0 0 8px 2px $shadow;
    position: relative;
    width: 12rem;

    .token-coin {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
    
    &.owned {
        &::after {
            content: '';
            border-radius: 2rem;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            box-shadow: inset 0 0 5px 3px $warning;
        }
        figure.prize-thumbnail {
            div::after, image::after {
                content: 'Won';
                position: relative;
                display: inline-block;
                font-size: xx-large;
                transform: rotate(45deg) scale(3) translate(2.1rem, 0);
                text-shadow: 1px 1px 1px $dark;
                color: $warning;
            }
        }
    }
    figure.prize-thumbnail {
        div, image {
            border-top-left-radius: 2em;
            border-top-right-radius: 2em;
        }
    }
    .prize-text {
        padding: 1em 0;
        display: flex;
        flex-direction: row;
        .prize-menu {
            flex-grow: 0;
            flex-basis: fit-content;
        }
        .prize-name {
            flex-grow: 1;
            margin: auto 0 auto 1rem;
            text-align: center;
        }
    }
}